function textAnimations() {
  var cons = document.querySelectorAll('.text-animation');

  if (cons) {
    cons.forEach(function (con) {
      var str = con.innerHTML;
      var arr = str.split(' ');
      con.innerHTML = '';
      arr.forEach(function (el) {
        if (el != "\n") {
          var newHTMLel = document.createElement('span')
          newHTMLel.innerHTML = el;
          newHTMLel.classList.add('animation__elem', 'hidden');
          con.appendChild(newHTMLel);
        }
      });

      var list = con.childNodes;
      var timer = 0;

      list.forEach(function (el) {
        setTimeout(function () {
          el.classList.remove('hidden');
        }, timer += 150);
      });
    })
  }
}

function animate() {
  textAnimations();
}

module.exports = {
  animate
}