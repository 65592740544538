import LazyLoad from "vanilla-lazyload";

const images = document.querySelectorAll("img.lazy, iframe");

const createLazyLoadInstance = () => {
  return new LazyLoad({
      unobserve_entered: true,
      webp: true,
      elements_selector: ".lazy"
    },
    images
  );
};

window.LazyLoad = createLazyLoadInstance;

const delayedCreateLazyLoadInstance = () => {
  setTimeout(createLazyLoadInstance, 100);
}

window.delayedLazyload = () => {
  return new LazyLoad({
    elements_selector: ".lazy"
  });
}

document.addEventListener("DOMContentLoaded", createLazyLoadInstance);
const callback = (mutationsList, observer) => {
  new LazyLoad({
    elements_selector: ".lazy",
  }, document.querySelectorAll('.lazy'));
};
const observer = new MutationObserver(callback);
const targetNode = document.querySelector('.tabs'); // Adjust the selector as needed
if (targetNode) {
  const config = { childList: true, subtree: true };
  observer.observe(targetNode, config);
}